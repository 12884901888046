import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import tw, { css } from 'twin.macro'
import { global } from '../styles/global'
import { StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'
import Image from './image'
import Text from './text'

const Tabs = ({ tabs, style }) => {
  const [current, setCurrent] = useState(0)
  return (
    <>
      <div css={[tw`hidden lg:block`, style]}>
        <header css={tw`flex flex-row items-stretch`}>
          {tabs.map((tab, i) => (
            <div
              key={i}
              role="button"
              tabIndex="0"
              onKeyPress={() => setCurrent(i)}
              onClick={() => setCurrent(i)}
              css={[
                tw`flex-1 px-12 pt-10 pb-6 text-center transition-all duration-500 ease-in-out bg-secondary-100`,
                current !== i && tw`mt-4 opacity-50 bg-primary-100`,
              ]}
            >
              <Text
                content={tab.subtitleNode}
                textType="body-lg"
                style={css`
                  strong {
                    ${tw`leading-button tracking-h4`}
                    font-size: 1.75rem;
                  }
                `}
              />
              <Heading
                content={tab.titleNode}
                headingType="h3"
                style={css`
                  strong {
                    ${tw`leading-button tracking-h4`}
                    font-size: 1.75rem;
                  }
                `}
              />
            </div>
          ))}
        </header>
        <section css={tw`flex flex-row overflow-hidden`}>
          {tabs.map((tab, i) => {
            const points = get(tab, 'descriptionNode.childMarkdownRemark.html').split('\n')
            return (
              <article
                key={i}
                css={[
                  tw`flex-none w-full p-16 lg:(px-48 pt-22 pb-20)`,
                  current !== i
                    ? tw`transition-colors duration-500 ease-in-out bg-primary-50`
                    : css`
                        ${tw`bg-secondary-100`}
                        transform: translateX(${`-${100 * i}%`})
                      `,
                ]}
              >
                <div
                  css={[
                    tw`flex flex-col items-center w-full transition-opacity duration-500 ease-in-out opacity-0`,
                    current === i && tw`opacity-100`,
                  ]}
                >
                  <div css={tw`flex flex-row w-full space-x-16`}>
                    {points.map((point, y) => (
                      <div key={y} css={tw`flex flex-col items-center flex-1`}>
                        {tab.images ==! undefined && <div css={tw`mb-10 w-14 h-14`}>
                          <Image image={tab.images[y]} />
                        </div>}
                        <Heading
                          headingType="h5"
                          content={{ childMarkdownRemark: { html: point } }}
                          style={css`
                            ${tw`text-lg text-left`}
                            strong {
                              ${global`typography.h5`}
                              ${tw`font-bold`}
                            }
                            strong:last-of-type {
                              ${tw`block mb-4`}
                            }
                          `}
                        />
                      </div>
                    ))}
                  </div>
                  {tab.callToAction && <Button
                    type="primary"
                    size="sm"
                    theme="navy-yellow"
                    label={tab.callToAction.label}
                    link={tab.callToAction.link}
                    style={tw`mt-16`}
                  />}
                </div>
              </article>
            )
          })}
        </section>
      </div>
      <div css={[tw`flex flex-col lg:hidden`, style]}>
        {tabs.map((tab, i) => {
          const points = get(tab, 'descriptionNode.childMarkdownRemark.html').split('\n')
          return (
            <div key={i} css={tw`flex flex-col`}>
              <a href="#header"><header
                role="button"
                id="header"
                tabIndex="0"
                onKeyPress={() => setCurrent(i)}
                onClick={() => setCurrent(i)}
                css={[
                  tw`flex-1 px-12 py-8 text-center transition-all duration-500 ease-in-out bg-secondary-100`,
                  current !== i && tw`opacity-50 bg-primary-100`,
                ]}
              >
                <Text
                  content={tab.titleNode}
                  textType="body-md"
                  style={css`
                    strong {
                      ${tw`tracking-sm leading-mobile-xs text-mobile-2xl
                      lg:(leading-button tracking-h4 text-mobile-3xl)`}
                    }
                  `}
                />
              </header></a>
              <section
                css={[
                  tw`flex flex-col overflow-hidden transition-all duration-500 ease-in-out`,
                  current !== i ? tw`bg-primary-50 max-h-0` : tw`bg-secondary-100 max-h-240`,
                ]}
              >
                <article css={[tw`w-full px-4 pt-4 pb-10`]}>
                  <div
                    css={[
                      tw`flex flex-col items-center w-full transition-opacity duration-500 ease-in-out opacity-0`,
                      current === i && tw`opacity-100`,
                    ]}
                  >
                    <div css={tw`flex flex-col w-full space-y-12`}>
                      {points.map((point, y) => (
                        <div key={y} css={tw`flex flex-col items-center flex-1`}>
                          {tab.images ==! undefined && <div css={tw`mb-6 w-14 h-14`}>
                            <Image image={tab.images[y]} />
                          </div>}
                          <Heading
                            headingType="h5"
                            content={{ childMarkdownRemark: { html: point } }}
                            style={css`
                              ${tw`text-lg text-left`}
                              strong {
                                ${global`typography.h5`}
                                ${tw`font-bold`}
                              }
                              strong:last-of-type {
                                ${tw`block mb-4`}
                              }
                            `}
                          />
                        </div>
                      ))}
                    </div>
                    {tab.callToAction && <Button
                      type="primary"
                      size="sm"
                      theme="navy-yellow"
                      label={tab.callToAction.label}
                      link={tab.callToAction.link}
                      style={tw`mt-10`}
                      hideCaret
                    />}
                  </div>
                </article>
              </section>
            </div>
          )
        })}
      </div>
    </>
  )
}

Tabs.defaultProp = {
  tabs: [],
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
}

export default Tabs
